import React from 'react';
import Button from "./Button";

const ValueProp = props => {

  return (
    <section id="Home" className="value-prop">
      <h1 className="tagline">Slot Online Indonesia Terbesar</h1>
      <div className="value-prop-content">
        <div className="main-photo"></div>
        <div className="main-message">
          <h3>Menang mudah, Jackpot milyaran</h3>
          <p className="text-justify text-lowercase font-weight-normal">
            slot online indonesia adalah media terbesar dan terpercaya untuk anda para penggila permainan slot online. Jenis permainan slot dibagi atas 3 jenis yaitu slot 2D, 3D dan 5D. Inti dari bermain slot online indonesia sangat mudah yaitu hanya menebak angka atau gambar apa yang keluar setelah mesin slot nya berhenti. 
            kemudian kita sebut dengan slot online indonesia adalah media dengan hadiah jackpot terbesar yakni 1 milyar.
            kemudian kita sebut dengan slot online indonesia sudah mempunyai member atau anggota hingga 250 ribu anggota, dengan rata-rata deposit anggota hingga 50 milyar perhari.
          </p>
        </div>
      </div>
    </section>
  )
};

export default ValueProp;

import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Home from "../components/Home";
import News from "../components/berita";
import ProductFeatures from "../components/ProductFeatures";
import Testimoni from "../components/testimoni";
import About from "../components/about";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Home />
    <News />
    <ProductFeatures />
    <Testimoni />
    <About />
  </Layout>
);

export default IndexPage;

import React from 'react';
import Button from "./Button";

const About = props => {

  return (
    <section id="about" className="cta">
  
      <div className="value-prop-content">
        <div className="main-message">
          <h3>Alamat</h3>
          <p className="text-justify text-lowercase font-weight-normal">
            Jl. Ahmad Yani No.167 A, Pleburan, Kec. Semarang Sel., Kota Semarang, Jawa Tengah 50241
          </p>
        </div>
        <div className="main-photo-buttom">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126715.79607413404!2d110.34702433479681!3d-7.024724603674103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e708b4d3f0d024d%3A0x1e0432b9da5cb9f2!2sSemarang%2C%20Kota%20Semarang%2C%20Jawa%20Tengah!5e0!3m2!1sid!2sid!4v1643850460534!5m2!1sid!2sid" width="600" height="450" allowfullscreen="" loading="lazy"></iframe>
        </div>

      </div>
    </section>
  )
};

export default About;

import React from 'react';

const Testimoni = props => {

  return (
    <section id="testimoni" className="testimonials">
      <h1>Here&apos;s What Our Customers Are Saying</h1>
      <div className="testimonial-group">
        <div className="testimonial-card-1">
          <div className="testimonial-1-photo"></div>
          <div className="testimonial-1-message">
            <h2 className="testimonial-1-name">Rizal Sutejo</h2>
            <p className="testimonial-1-title">Customers</p>
            <blockquote className="testimonial-1-content">web slot online indonesia terpercaya.</blockquote>
          </div>
        </div>
        <div className="testimonial-card-2">
          <div className="testimonial-2-photo"></div>
          <div className="testimonial-2-message">
          <h3 className="testimonial-2-name">Budi Susanto</h3>
          <p className="testimonial-2-title">Customers</p>
          <blockquote className="testimonial-2-content">web slot online indonesia jackpot milyaran terpercaya.</blockquote>
        </div>
      </div>
      <div className="testimonial-card-3">
        <div className="testimonial-3-photo"></div>
        <div className="testimonial-3-message">
        <h3 className="testimonial-3-name">Dedi Siswanto</h3>
        <p className="testimonial-3-title">Customers</p>
        <blockquote className="testimonial-3-content">web slot online indonesia terbesar.</blockquote>
      </div>
        </div>
      </div >
    </section >
  )
};

export default Testimoni;